import getIsInMsTeams from 'helpers/getIsInMsTeams';
import getIsInMsOffice from 'helpers/getIsInMsOffice';
import getIsInMs365 from 'helpers/getIsInMs365';

export default function getIsInMicrosoft() {
  const isInMsTeams = getIsInMsTeams();
  const isInMsOffice = getIsInMsOffice();
  const isInMs365 = getIsInMs365();

  return isInMsTeams || isInMsOffice || isInMs365;
}
